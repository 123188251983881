import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://95372bc36792415c930102ba2d6b3f90@o315385.ingest.sentry.io/4504965855117312",
  debug: false,
  environment: "preview",
  release: "front-end-two-col-template-rebooted@35d10bfdc1d6f44930e8777e86fd25fbfc15a060",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1,
});